import styled, { createGlobalStyle } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const Preloader = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  z-index: 100;
  opacity: 1;
  transition: opacity .3s ease-in-out .1s;
  
  ${({ hide }) => hide && `
    pointer-events: none;
    opacity: 0;
  `};
`;

export const BodyStyles = createGlobalStyle`
  body {
    background-color: ${props => (props.whiteColor ? '#FFF' : '#000')};
    padding: 0;
    margin: 0;
  }
  
  *, *:before, *:after {
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
  }
  
  img {
    max-width: 100%;
  }
  
  .overlay-open {
    .language-selector {
      display: none !important;
    }
  }
     
  button {
    padding: 0;
    margin: 0;
    background: 0;
	border: none;
	outline: none;
  }
  
  .noscroll {
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
  }
  
  .no-scroll {
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
  }
  
  .absolute {
    //top: 110px;
    position: absolute;
  }
  
  .machineInnerFilter {
    ${breakpoint('tablet')`
      display: flex;
    `};
  }
  
  /*
   Machine Filtering
   */
  .filterContainer {
    margin: -10px 30px 0;
    
    .Features {
      button {
        &:last-child {
          border-left: 1px solid #F0F0F0;
        }
      }
    }
    
    button {
    font-size: 12px;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F0F0F0;
    padding: 10px;
    
    svg {
      margin-right: 5px;
    }
    
      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-right: 0;
      }
      
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: 0;
      }
    }
  }
  
  .resultsContainer {
    margin: 20px -15px 0 0;
    
    ${breakpoint('tablet')`
      margin: 20px -25px 0 0;
    `};
  }
  
  /*
   Article Filtering
   */
  .articleFilterContainer {
    margin: 0 0;
    
    ${breakpoint('tablet')`
      margin: 0 -15px 0 0;
    `};
    
    button {
      margin-right: 5px;
      border-radius: 5px;
      font-size: 12px;
      padding: 10px;
    }
    
    &.eventsActive {
      .filterCategory {
        &:last-of-type {
          display:block;
        }
      }
    }
    
    .filterCategory {
      display: block;
      
        &:last-of-type {
          display:none;
        }
      
      button {
        margin-bottom: 10px;
        
        &:last-of-type {
          //margin-bottom: 0;
        }
      }
    }
  }
  
  .articleResultsContainer {
    margin: 20px 0 0;
    
    article {
      margin-top: 15px;
      vertical-align: top;
      
      ${breakpoint('tablet')`
        width: calc(25% - 15px);
      `};
    }
  }
  
  .menuInnerContainer {
    margin-top: 0 !important;
  }
  
  iframe {
    display: block;
    margin: 0 auto;
    
    width: 80vw !important;
    height: 50vw;
    
    @media (min-width: 768px) {
      width: 40vw !important;
      height: 22.5vw;
    }
  }
  
  @media (min-width: 768px) {
    .hasMachine {
      .socialLinks {
        position: absolute;
        display: flex;
        flex-direction: column;
        max-width: 1000px;
        margin: auto;
        left: 50%;
        margin-left: -400px;
        
        a {
          height: 45px;
        }
      }
      
      .machineContainer {
        position: absolute;
        left: 50%;
        margin-left: 200px;
        
        > div {
          width: 300px;
          
          > div {
            -webkit-box-shadow: 0px 0px 65px -10px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 65px -10px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 65px -10px rgba(0,0,0,0.75);
          }
        }
      }
      
      .richTextContainer {
        &:first-of-type {
          width: 55% !important;
          margin-left: 12% !important;
          background: none;
        }
      }
      
      .socialLinks {
        margin-left: -450px;
      }
      
      .heroImageContainer {
        margin-bottom: 50px;
      }
    }
  }
  
  .languageActive {
    .arrow {
      top: 165px;
    }
  }
 `;

export const MOBILE_SIDE_PADDING = '30px';
