import React, { Fragment } from 'react'
import SVG from 'react-inlinesvg'
import {window} from 'browser-monads';
import { graphql, Link, StaticQuery } from 'gatsby'
import {
FooterContainer,
Logo,
Signature,
Copyright,
SocialLink,
SocialLinks,
SocialLinksContainer,
Links,
InnerContainer,
SocialLinksInner,
LogoContainer,
SecondaryLinks,
LanguageSelectorContainer,
QRCodeContainer,
QRCode,
QRCodeContainerLink,
Columns,
LinksOutter,
LinkCol,
AddressCol,
AddressPhone,
AddressText,
ColSubscribe
} from './styles'
import { isWide } from '../../utils/responsive'
import MiniLanguageSelector from '../mini-language-selector/index'
import SubscribeFooter from '../subscribe-footer/subscribeFooter'

const renderSocialLink = link => (
<SocialLink key={link.social_link.url} href={link.social_link.url} target="_blank">
	<SVG src={link.icon.url} />
</SocialLink>
)


function getTarget(item) {
	const {title, link} = item;

	if(!link || !link.text){
		return null;
	}


	if(link.text && link.text.indexOf('http') >=0){
		return <p key={title + link.text}><a href={link.text} target="_blank">{title}</a></p>;
	}

	return <p key={title + link.text}><Link to={link.text}>{title}</Link></p>;
}

function isChina() {
	if(!window || !window.location) return false;
	return window.location.pathname.indexOf('/cn/') >= 0;
}

const FooterUI = ({
	termsCondition,
	privacyPolicy,
	followUs,
	login,
	copyright,
	showQRCode,
	qrCode,
	qrCodeLink,
	socialLinks,
	logo,
	address,
	phone,
	map_label,
	map_link,
	col1_title,
	col1_links,
	col2_title,
	col2_links,
	col3_title,
	col3_links,
	col4_title,
	col4_links
}) => (
<FooterContainer>
	{showQRCode && (
	<QRCodeContainer>
		<QRCodeContainerLink target="_blank" href={qrCodeLink}>
		<QRCode src={qrCode} />
		</QRCodeContainerLink>
	</QRCodeContainer>
	)}
	<InnerContainer>
		<Logo>
			<LogoContainer>
				{/* <img src={logo} /> */}
				{!isChina() && <SVG src = "/images/EVERSYS_brand_logo_White.svg" />}
				{isChina() && <SVG src = "/images/Eversys_logo_china.svg" />}
			</LogoContainer>
		</Logo>
		<Signature>
			<SVG src="/images/supertraditional-inline.svg" />
		</Signature>
		<SocialLinksContainer>
			<SocialLinksInner>
			<p>Follow Us</p>
			<SocialLinks>
				{socialLinks.map(link => renderSocialLink(link))}
			</SocialLinks>
			</SocialLinksInner>
		</SocialLinksContainer>
	</InnerContainer>



	<Columns>
		<LinksOutter>
			<LinkCol>
				<p><strong>{col1_title}</strong></p>
				{col1_links.map(item => getTarget(item))}
			</LinkCol>
			<LinkCol>
				<p><strong>{col2_title}</strong></p>
				{col2_links.map(item => getTarget(item))}
			</LinkCol>
			<LinkCol>
				<p><strong>{col3_title}</strong></p>
				{col3_links.map(item => getTarget(item))}
			</LinkCol>
			<LinkCol>
				<p><strong>{col4_title}</strong></p>
				{col4_links.map(item => getTarget(item))}
			</LinkCol>

		</LinksOutter>
		
		<ColSubscribe>
			<SubscribeFooter />
		</ColSubscribe>
		
		<AddressCol>
			<AddressText dangerouslySetInnerHTML={{__html: address}}></AddressText>
			<AddressPhone>{phone}</AddressPhone>
			<a href={map_link.url} target={map_link.target}>{map_label}</a>
		</AddressCol>
	</Columns>



	{isWide && <Links>
		<Copyright> {copyright} </Copyright>

		<SecondaryLinks>
			<Fragment>
				<MiniLanguageSelector />
			</Fragment>
		</SecondaryLinks>
	</Links>}
	
	{!isWide && (
	<Fragment>
		<LanguageSelectorContainer>
			<MiniLanguageSelector />
		</LanguageSelectorContainer>
		<Copyright>{copyright}</Copyright>
	</Fragment>
	)}
</FooterContainer>
)

const Footer = () => (
<Fragment>
	<StaticQuery
	query={query}
	render={data => (
		<FooterUI
			termsCondition={
				data.allPrismicFooter.edges[0].node.data.terms_and_conditions_label.text
			}
			privacyPolicy={
				data.allPrismicFooter.edges[0].node.data.privacy_policy_label.text
			}
			followUs={
				data.allPrismicFooter.edges[0].node.data.follow_us_label.text
			}
			login={data.allPrismicFooter.edges[0].node.data.login_label.text}
			copyright={
				data.allPrismicFooter.edges[0].node.data.copyright_label.text
			}
			showQRCode={
				data.allPrismicFooter.edges[0].node.data.show_qr_code_ === 'Yes'
			}
			qrCode={data.allPrismicFooter.edges[0].node.data.qr_code.url}
			logo={data.allPrismicFooter.edges[0].node.data.logo.url}
			qrCodeLink={data.allPrismicFooter.edges[0].node.data.qr_link.url}
			socialLinks={data.allPrismicSocialLinks.edges[0].node.data.body[0].items}
			address={data.allPrismicFooter.edges[0].node.data.address.html}
			phone={data.allPrismicFooter.edges[0].node.data.phone}
			map_label={data.allPrismicFooter.edges[0].node.data.map_label}
			map_link={data.allPrismicFooter.edges[0].node.data.map_link}
			col1_title={data.allPrismicFooter.edges[0].node.data.col1_title}
			col1_links={data.allPrismicFooter.edges[0].node.data.col1_links}
			col2_title={data.allPrismicFooter.edges[0].node.data.col2_title}
			col2_links={data.allPrismicFooter.edges[0].node.data.col2_links}
			col3_title={data.allPrismicFooter.edges[0].node.data.col3_title}
			col3_links={data.allPrismicFooter.edges[0].node.data.col3_links}
			col4_title={data.allPrismicFooter.edges[0].node.data.col4_title}
			col4_links={data.allPrismicFooter.edges[0].node.data.col4_links}
		/>
	)}
	/>
</Fragment>
)

const query = graphql`
query FooterQuery {
	allPrismicFooter {
		edges {
			node {
				data {
					col1_links {
						link {
							text
						}
						title
					}
					col1_title
					col2_links {
						link {
							text
						}
						title
					}
					col2_title
					col3_links {
						link {
							text
						}
						title
					}
					col3_title
					col4_links {
						link {
							text
						}
						title
					}
					col4_title
					address {
						html
					}
					phone
					map_label
					map_link {
						url
						target
					}
					privacy_policy_label {
						text
					}
					terms_and_conditions_label {
						text
					}
					login_label {
						text
					}
					copyright_label {
						text
					}
					follow_us_label {
						text
					}
					show_qr_code_
					qr_code {
						url
					}
					qr_link {
						url
					}
					logo {
						url
					}
				}
			}
		}
	}
	allPrismicSocialLinks {
		edges {
			node {
			data {
				body {
				items {
					icon {
					url
					}
					social_link {
					url
					}
				}
				}
			}
			}
		}
	}
}
`

export default Footer
