import { check } from './stringUtils'



export const orderByProps = (arr, p1, reverse) => {
	arr.sort(function (item1, item2) {

		if (item1[p1] > item2[p1]) return reverse ? -1 : 1;
		if (item1[p1] < item2[p1]) return reverse ? 1 : -1;
		
		return 0;
	});
	return arr;
}



export const extractCareers = jobs => {
	return jobs.edges.map(job => {
		const a = job.node;
		const data = job.node.data;

		return {
			title: data.title.text,
			job_type: data.job_type,
			description: data.description,
			id: a.uid || a.id,
			tags: data.tags.split('\n'),
			//searchableTags: data.searchable_tags__comma_separated_.text.split(',').map(elem => elem.trim()).filter(elem => elem !== ""),
			image: data.image.url,
			pdf_file: data.pdf_file,
			index: data.datetime,
			datetime: data.datetime,
		}
	})
};
  
export const getCareersTags = (jobs) => {
	const tags = [];

	jobs.map(job => {
		const tag = job.tags;
		tags.push(...tag);
	});

	const simple = Array.from(new Set(tags));
	return simple.map(item => ({ text: item }));
};


export const getMachineTypes = machine => machine.body1[0].items

export const getMachineTypesCoreInformation = machineTypes =>
  machineTypes.map((item, i) => ({
    name: item.machine_type.document[0].data && item.machine_type.document[0].data.machine_type_name && item.machine_type.document[0].data.machine_type_name.text ? item.machine_type.document[0].data.machine_type_name.text : '',
    image:
      item.machine_type.document[0].data &&
      item.machine_type.document[0].data.machine_image &&
      item.machine_type.document[0].data.machine_image.url
        ? item.machine_type.document[0].data.machine_image.url
        : '',
    id: i,
	useBlack:
		item.machine_type.document[0] &&
		item.machine_type.document[0].data &&
		item.machine_type.document[0].data.useblack
		? item.machine_type.document[0].data.useblack === 'Yes'
		: '',
  }))

export const getMachineTitle = (machine, typesBaseInfo, selectedTypeIndex) => {
  if (check(machine.machine_name) && machine.machine_name.text === 'Cameo') {
    return machine.machine_name.text
  } else {
    return typesBaseInfo && typesBaseInfo.length
      ? typesBaseInfo[selectedTypeIndex].name
      : ''
  }
}

export const getMachineTypeData = machineType =>
  machineType.machine_type.document[0].data
export const getMachineTypeUrl = machineType =>
  machineType.machine_type.document[0].uid
export const getMachineHeadingImages = (machine, type) => {
  if (
    machine &&
    machine.body2 &&
    machine.body2.length > 0 &&
    machine.body2[0].items
  ) {
    return machine.body2[0].items.map(item => {
      const obj = {
        desktop_background_image: item.desktop_machine_background,
        background_image: item.background_image,
        desktop_machine_image: item.desktop_machine_image,
        machine_image: item.machine_image,
        colour_name: item.colour_name,
        icon_image: item.icon_image,
      }

      return obj
    })
  } else {
    return [
      {
        desktop_background_image: type.desktop_machine_background,
        background_image: type.machine_background,
        desktop_machine_image: type.desktop_machine_image,
        machine_image: type.machine_image,
      },
    ]
  }
}
export const getPageComponents = body =>
  body && body.length > 0 && body[0].items && body[0].items.length > 0
    ? body[0].items
    : []

export const parseMachineLevelsData = data => {
	const machines = data.edges || data;
	// console.log("machines", machines)

	const list = [];
	for (const item of machines) {
		const machine = {
			title: item.node.data.title.text,
			image: item.node.data.image.url,
			uid: item.node.uid,
			menu_order: item.node.data.menu_order ? item.node.data.menu_order : 999
		}
		list.push(machine);
	}

	const ordered = orderByProps(list, 'menu_order');
	
  	return ordered;
}


export const parseMachineLinesData = data => {
	const lines = data.edges;

	const list = [];
	for (const item of lines) {
        // console.log("item", item)
		const line = {
			title: item.node.data.title.text,
			image: item.node.data.image.url,
			uid: item.node.uid,
			machine_overview_parent: item.node.data.machine_overview.uid,
			menu_order: item.node.data.menu_order ? item.node.data.menu_order : 999
		}
		list.push(line);
	}
	const ordered = orderByProps(list, 'menu_order');

	return ordered;
}


export const parseMachineRangeData = data => {
	const machines = data.edges || data;
	// console.log("machines", machines)

	const list = [];
	for (const item of machines) {
		const machine = {
			title: item.node.data.title.text,
			image: item.node.data.image.url,
			uid: item.node.uid,
			milk_systems: item.node.data.milk_systems,
			milk_systems_2: item.node.data.milk_systems_2,
			milk_systems_3: item.node.data.milk_systems_3,
			expresso_count: item.node.data.expresso_count,
			machine_overview_parent: item.node.data.machine_overview_parent.uid,
			machine_link: item.node.data.machine_link.uid,
		}
		list.push(machine);
	}

	// console.log("list", list)
	
  	return list;
}




export const parseMachineLinePlaceholders = (edges) => {
	const items = [];
	if(!edges || !edges.edges) {
		return []
	}
	for (let i = 0; i < edges.edges.length; i++) {
		const data = edges.edges[i].node.data;
		const item = {
			isPlaceholder: true,

			title: data.title.text,
			name: data.title.text,
			image: data.image.url,
			navImage: data.image.url,
			link: data.link && data.link.text ? data.link.text : null,
			overview: data.overview.uid
		}

		items[i] = item;
	}
	return items;
}



export const parseMachineTypePlaceholders = (edges) => {
	const items = [];
	if(!edges || !edges.edges) {
		return []
	}
	for (let i = 0; i < edges.edges.length; i++) {
		const data = edges.edges[i].node.data;
		const item = {
			isPlaceholder: true,

			milk_systems: data.milk_systems,
			milk_systems_2: data.milk_systems_2,
			milk_systems_3: data.milk_systems_3,
			espressoCount: data.espresso_count,
			title: data.title.text,
			name: data.title.text,
			image: data.image.url,
			navImage: data.image.url,
			link: data.link && data.link.text ? data.link.text : null,
			line: data.line.uid
		}

		items[i] = item;
	}
	return items;
}


const getTypes = (machine) => {
    // console.log("getTypes -> machine", machine)
	if(!machine) return null;
	if(!machine.body1) return null;
	if(machine.body1.length <= 0) return null;
	if(!machine.body1[0].items) return null;

	const items = machine.body1[0].items || null;

	if(!items || items.length <= 0) return null;
    // console.log("getTypes -> items", items)

	const types = [];
    for (let i = 0; i < items.length; i++) {
		const item = items[i];
		types[i] = item &&  item.machine_type &&  item.machine_type.document && item.machine_type.document[0] ? item.machine_type.document[0] : null;
	}
	
	// console.log("getTypes -> types", types)
	return types;
	
}

export const getMachineNavigationData = (machine, uid, line) => {
    // console.log("getMachineNavigationData -> machine", machine)
	// machine-e2ct#classic-e2m
	return {
		name: machine.machine_name.text,
		espressoCount: machine.espresso_count,
		milk_systems: machine.milk_systems,
		milk_systems_2: machine.milk_systems_2,
		milk_systems_3: machine.milk_systems_3,
		accessories: machine.accessories,
		types: getTypes(machine),
		line,
		features:
			machine.machine_name.text === 'Cameo'
			? [machine.features, 'Bean Only']
			: machine.features,
		url: uid, //machine-e2ct#classic-e2m
		navImage: machine.navigation_image.url,
}
}

export const getMachineTypeNavigationData = (machine, uid, parentUid, line) => ({
	name: machine.machine_type_name.text,
	espressoCount: machine.espresso_count,
	milk_systems: machine.milk_systems,
	milk_systems_2: machine.milk_systems_2,
	milk_systems_3: machine.milk_systems_3,
	accessories: machine.accessories,
	line,
	features: machine.features,
	url: parentUid + '#' + uid,
	navImage: machine.navigation_image.url,
})

//machine-e2ct#classic-e2m
export const parseMachineNavigationData = (data) => {
	const machines = data.edges || data

	const navigationData = machines.map(m => {
		const machine = m.node.data || m;
        // console.log("parseMachineNavigationData -> machine", machine)
		const line = machine && machine.machine_line && machine.machine_line.uid ? machine.machine_line.uid : null;
		
		// if (machine.treat_as_single_machine === 'Yes') {
        //     console.log("parseMachineNavigationData -> machine.treat_as_single_machine", machine.treat_as_single_machine)
			return getMachineNavigationData(machine, m.node.uid, line);
		
		
		// // } else {
			// const machineTypes = getMachineTypes(machine);
			// const types = machineTypes.map(t => { //machine.body1[0].items
			// 	const type = getMachineTypeData(t); //machineType.machine_type.document[0].data
			// 	const url = getMachineTypeUrl(t); //machineType.machine_type.document[0].uid
			// 	// console.log("url", url, "   parentID:", m.node.uid);
			// 	return getMachineTypeNavigationData(type, url, m.node.uid, line);
			// })

			// return flatten(types)
		// // }
	})

	const flattened = flatten(navigationData)

	return flattened
}


export const flatten = arr => {
	return arr.reduce((flat, toFlatten) => {
		return flat.concat(
			Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
		)
	}, [])
}

export const extractArticles = articles => {
  return articles.edges.map(article => {
    const a = article.node
    const data = article.node.data

	const thumb_click_pdf_link = (data.thumb_click_pdf_link && data.thumb_click_pdf_link.url && data.thumb_click_pdf_link.url.length > 15) ? data.thumb_click_pdf_link : false; 

    return {
      title: data.title.text,
      subHeading:
        data.subheading && data.subheading.text && data.subheading.text !== null
          ? data.subheading.text
          : '',
      id: a.uid,
      tags: data.tags.split('\n'),
      searchableTags: data.searchable_tags__comma_separated_.text
        .split(',')
        .map(elem => elem.trim())
        .filter(elem => elem !== ''),
      previewImage: data.preview_image.url,
      shortPreview: data.short_preview.text,
      region: data.region && data.region.text ? data.region.text : '',
      index: data.article_order_index,
	  thumb_click_pdf_link
    }
  })
}

export const getArticleTags = articles => {
  const tags = []

  articles.map(article => {
    const tag = article.tags
    tags.push(...tag)
  })

  const simple = Array.from(new Set(tags))
  return simple.map(item => ({ text: item }))
}

export const getArticleRegions = articles => {
  const regions = []

  articles.map(article => {
    const region = article.region
    if (region !== '') regions.push(region)
  })

  const simple = Array.from(new Set(regions))
  return simple.map(item => ({ text: item }))
}

export const extractAddress = office => ({
  name: office.company_name && office.company_name.text,
  street: office.street && office.street.text,
  city: office.city && office.city.text,
  country: office.country && office.country.text,
  zip: office.zip && office.zip.text,
  phone: office.phone && office.phone.text,
  mapsLink: office.maps_link && office.maps_link.url,
  website: office.website && office.website.url,
  countryCode: office.country_code && office.country_code.text,
})

export const getTagColour = tag => {
  if (typeof tag !== 'string') return ''

  switch (tag.toLowerCase()) {
    case 'news':
    case 'neuigkeiten':
    case 'production':
      return '#da905e'
    case 'blog':
    case 'technical':
    case '博客':
      return '#e6c454'
    case 'case study':
    case 'etudes de cas':
    case 'fallstudien':
    case 'administration':
      return '#629a8d'
    case 'event':
    case 'evénement':
    case 'other':
    case '活动':
      return '#637a9b'
    default:
      return '#000'
  }
}


const machines = {
	"cameo":    	{name: "Cameo", 		milk_systems: ['1-Step', '1.5-Step', '2-Step'], 	accessories: ['Cup Heater', 'Fridge', 'Chocolate']},
	"e'6": 		    {name: "e'6", 			milk_systems: ['2-Step'], 							accessories: ['Cup Heater']},
	"e'6m": 		{name: "e'6m", 			milk_systems: ['1-Step', '2-Step'], 				accessories: ['Cup Heater']},
	"e'4": 		    {name: "e'4", 			milk_systems: ['2-Step'], 							accessories: ['Cup Heater']},
	"e'4m": 	  	{name: "e'4m", 			milk_systems: ['1-Step', '2-Step'], 				accessories: ['Cup Heater']},
	"e'4m s/s": 	{name: "e'4m s/s", 		milk_systems: ['1-Step'], 							accessories: ['Cup Heater']},
	"e'2": 		    {name: "e'2", 			milk_systems: ['2-Step'], 							accessories: ['Cup Heater']},
	"e'2ct":      	{name: "e'2ct", 		milk_systems: [], 									accessories: ['Cup Heater']},
	"e'2m":       	{name: "e'2m", 			milk_systems: ['1-Step', '2-Step'], 				accessories: ['Cup Heater']},
	"shotmaster": 	{name: "Shotmaster", 	milk_systems: [], 									accessories: ['Cup Heater']},
}


export const getMilkSystem = (machineName, prop) => {
	if(!machineName || !prop) return false;

	const item = machines[machineName.toLowerCase()];
	const matches = (item.milk_systems.indexOf(prop) >= 0);
	//console.log(prop, matches)
	return matches;
}

export const getAccessories = (machineName, prop) => {
	if(!machineName || !prop) return false;

	const item = machines[machineName.toLowerCase()];
	const matches = (item.accessories.indexOf(prop) >= 0);
	//console.log(prop, matches)
	return matches;
}