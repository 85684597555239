import React  from 'react';
import { PropTypes } from 'prop-types';
import SVG from 'react-inlinesvg';

import {
  getTagColour, getMilkSystem, getAccessories
} from '../../utils/selectors';

import {
	FilterContainer,
	Filters,
	IndividualFilter,
	FilterButton,
	Results,
	SearchBox,
	Label,
	FilterItemContainer,
	SearchContainer,
	FiltersContainer,
} from './styles';

class Filter extends React.Component {
  static propTypes = {
	categories: PropTypes.instanceOf(Array).isRequired,
	items: PropTypes.instanceOf(Array).isRequired,
	itemRenderer: PropTypes.instanceOf(Object).isRequired,
  };

  static defaultProps = {
    	initialFilters: [],
  };

	constructor(props) {
		super(props);

		this.state = {
			activeFilters: props.initialFilters,
			elements: this.props.items,
			eventsActive: false,
		};
	}

	componentDidMount() {
		this.filterElements();
	}

	toggleFilterValue = (evt) => {
		const { activeFilters } = this.state;
		const filterData = evt.currentTarget.value.split(',');
		const category = filterData[0];
		const option = filterData[1];
		let filter = activeFilters[category];
		//console.log(filter);
		//console.log(this.props.categories);
		//console.log(category, option);

		if(filter && filter.name === 'tags' /*&& activeFilters && activeFilters['region'] && activeFilters['region'].active*/) {
			if (activeFilters && activeFilters['region'] && activeFilters['region'].active) {
				activeFilters['region'].active = [];
			}

			this.setState({ eventsActive: false });
		}

		/*
		* No filter, because this is the first time it's been selected.
		*/
		if(!filter) {
			activeFilters[category] = {
				name: category,
				active: [],
			};
		}

		filter = activeFilters[category];
		// console.log(option);
		// console.log(filter);

		/*
		* Check to see if current option for selected filter is active.
		*/
		if(filter.active.includes(option)) {
			const index = filter.active.indexOf(option);

			/*
			* If active, remove.
			*/
			if (index !== -1) {
				filter.active.splice(index, 1);

				const opt = option.toString();

				if (opt === 'Event' || opt === 'Evénement' || opt === '活动') {
					this.setState({ eventsActive: false });

					filter.active = [];
				}
			}
		} else {
			
			/*
			* Set option as active filter.
			*/

			if (option === 'Event' || option === 'Evénement' || option === '活动') {
				this.setState({ eventsActive: true });
			}

			
			if (this.props.singleFiltersOnly) {
				filter.active = [];
			}

			filter.active.push(option);
		}

		/*
		* Update active filters in state.
		*/
		this.setState({ activeFilters });

		//console.log(activeFilters);

		if (this.props.onFiltersUpdated) {
			this.props.onFiltersUpdated(activeFilters);
		}

		this.filterElements();
	};

	filterElements = () => {
		const { items } = this.props;
		const { activeFilters } = this.state;
        //console.log("Filter -> filterElements -> activeFilters", activeFilters)

		/*
		* Loop all items passed through.
		*/
		const filter = items.filter(item => {
			let matches = true;
			//console.log(item.name); //Cameo

			for(let key in activeFilters) {
				if(activeFilters.hasOwnProperty(key)) {
					/*
					* For each item, check all active filters against it.
					* If one fails, the item is excluded from search results.
					*/
					const filter = activeFilters[key]; 	// selected filter
					const prop = item[filter.name]; 	// machine filter
					
					/* If a filter has no active options, show the item */
					if(filter.active.length === 0) return true;

					let subMatch = false;

					const filterName = filter['name'] ? filter['name'] : null;
					//console.log('filterName:', filter, '   |   prop:', prop);

					

					filter.active.forEach(option => {
						//console.log('active.option:', option);
						if(filterName === 'milk_systems') {
							subMatch = getMilkSystem(item.name, option);
							return;
						}
						
						if(filterName === 'accessories') {
							subMatch = getAccessories(item.name, option);
							return;
						}
						
						if(typeof prop === "object" && prop.length) {
							prop.filter(p => {
								if(p === option) {
									// console.log(option, '  |  p:',  p);
									subMatch = true;
								}
							})
						}

						if(prop === option) {
							// console.log(option, '  |  prop:',  prop);
							subMatch = true;
						}
					});

					if(!subMatch) return false;

					matches = subMatch;
				}
			}

			return matches;
		});

		//console.log('elements:', filter);

		this.setState({ elements: filter })
	};

	checkFilterIsActive = ({ name }, option) => {
		const { activeFilters } = this.state;
		let filter = activeFilters[name];
        //console.log("Filter -> checkFilterIsActive -> filter", filter)
		let isActive;

		if(filter) {
			isActive = filter.active.includes(option.toString());
            //console.log(name, "Filter -> checkFilterIsActive -> isActive", isActive)
			
			return isActive;
		}

		//console.log(name, "Filter -> checkFilterIsActive -> isActive", 'FALSE')
		return false;
	};

	renderFilterCategory = (category) => {
		//console.log(category);
		return (
			<FilterItemContainer key = { category.name } className = "filterCategory">
				{ category.displayName !== undefined && category.displayName !== '' && <p>{ category.displayName }</p> }
				<IndividualFilter className = { category.displayName }>
				{
					category.options.map(option => (
					<FilterButton
						key = { option.text }
						onClick = { this.toggleFilterValue }
						value = { [ category.name, option.text ] }
						active = { this.checkFilterIsActive(category, option.text) }
						className = { this.checkFilterIsActive(category, option.text)  ? 'active' : '' }
						color = { getTagColour( option.text ) }
					>
						{ option.icon && <SVG src = { option.icon } /> }
						{ !category.iconOnly && option.text }
					</FilterButton>
					))
				}
				</IndividualFilter>
			</FilterItemContainer>
		)
	};

	updateSearchBox = (evt) => {
		const searchTerm = evt.target.value.toLowerCase();
		const elements = this.state.elements;

		if(searchTerm === "") {
			this.filterElements();
			return;
		}

		const newElements = elements.filter(e => {
			return e.searchableTags.filter(elem => elem.toLowerCase().includes(searchTerm)).length > 0;
		});

		this.setState({ elements: newElements })
	};

  render() {
    const {
      categories,
      itemRenderer,
      customFilterClasses,
      customResultsClasses,
      customFiltersContainerClasses,
      hideSearch,
      onItemClicked,
      searchBoxLabel,
      filterLabel,
      searchLabel,
    } = this.props;

	const { eventsActive } = this.state;
	const filterClasses = eventsActive ? `${customFilterClasses} eventsActive` : `${customFilterClasses}`;

    return (
      <FilterContainer className = { filterClasses }>
        <Filters>
          <FiltersContainer className = { customFiltersContainerClasses }>
            { !hideSearch && <Label>{ filterLabel }</Label> }
            {
              categories.map(category => this.renderFilterCategory(category))
            }
          </FiltersContainer>
          <SearchContainer>
            { !hideSearch && <Label>{ searchBoxLabel }</Label> }
            { !hideSearch && <SearchBox placeholder = { searchLabel } onChange = { evt => this.updateSearchBox(evt) } /> }
          </SearchContainer>
        </Filters>
        <Results className = { customResultsClasses }>
          {
            this.state.elements.map(item => React.createElement(itemRenderer, { ...item, handleClick: onItemClicked, key: `item-${Math.random()}` }))
          }
        </Results>
      </FilterContainer>
    );
  }
}

export default Filter;
