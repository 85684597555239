import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

const MetaTags = ({ siteTitle, siteKeywords, siteDescription }) => {
	console.log('siteDescription :>> ', siteDescription);

	return (
	<Fragment>
		<Helmet>
		<meta charSet="utf-8" />
		<title>{ siteTitle }</title>
		<meta name="keywords" content={siteKeywords} />
		<meta name="viewport" content='width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no' />
		<meta name="description" content={siteDescription} />
		<link rel = "apple-touch-icon" sizes = "180x180" href = "/apple-touch-icon.png" />
		<link rel = "icon" type = "image/png" sizes = "32x32" href = "/favicon-32x32.png" />
		<link rel = "icon" type = "image/png" sizes = "16x16" href = "/favicon-16x16.png" />
		<link rel = "manifest" href = "/site.webmanifest" />
		<link rel = "mask-icon" href = "/safari-pinned-tab.svg" color = "#5bbad5" />
		<link href = "https://s3.eu-west-2.amazonaws.com/docs.share/styles.css" type = "text/css" />
		<meta name = "msapplication-TileColor" content = "#00a300" />
		<meta name = "theme-color" content = "#ffffff" />
		</Helmet>
	</Fragment>
	)
};

export default MetaTags;
