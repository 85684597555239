import React, { Fragment, useState } from 'react';
import { graphql, navigate, StaticQuery } from 'gatsby';

import {
  FormContainer,
  InnerContainer,
  TextInput,
  SelectInput,
  FormPrivacy,
  FormTitle,
  InputContainer,
  TextArea,
  Button,
} from './styles'

const SubscribeFooterUI = ({ heading, description, name, email, submit, success_message, error_message, introduction_footer, footer_policy }) => {

  const [checked, setChecked] = useState(false);

  const handleChange = event => {
    setChecked(!checked);
  }
  
  return (
  <FormContainer>
    <InnerContainer
      action = "https://eversys.us12.list-manage.com/subscribe/post?u=aa9839dad36eb7cbe13c0220b&amp;id=f1bda37d22"
      method = "post"
      target = "_blank"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
    >
      <FormTitle>{introduction_footer}</FormTitle>
      <InputContainer>
        <TextInput type = "email" name = "EMAIL" placeholder = { email } required/>
        <Button type = 'submit'  text = {submit} >SUBMIT</Button>
      </InputContainer>
      
      <FormPrivacy>
        <input type="checkbox" value={checked} onChange={handleChange} required />
        <p>{footer_policy}</p>
      </FormPrivacy>
    </InnerContainer>
  </FormContainer>
)};

const SubscribeFooter = () => (
  <Fragment>
    <StaticQuery
      query = { query }
      render = { data => (
        <SubscribeFooterUI
          heading = { data.allPrismicSubscriptionForm.edges[0].node.data.title.text }
          description = { data.allPrismicSubscriptionForm.edges[0].node.data.introduction.text }
          name = { data.allPrismicSubscriptionForm.edges[0].node.data.name_label.text }
          email = { data.allPrismicSubscriptionForm.edges[0].node.data.email_label.text }
          submit = { data.allPrismicSubscriptionForm.edges[0].node.data.submit_label.text }
          error_message = { data.allPrismicSubscriptionForm.edges[0].node.data.error_message }
          success_message = { data.allPrismicSubscriptionForm.edges[0].node.data.success_message }
          footer_policy = { data.allPrismicSubscriptionForm.edges[0].node.data.footer_policy.text }
          introduction_footer = { data.allPrismicSubscriptionForm.edges[0].node.data.introduction_footer.text }
        />
      ) }
    />
  </Fragment>
);

const query = graphql`
    query SubscribeFooterQuery {
        allPrismicSubscriptionForm {
            edges {
                node {
                    data {
                        title {
                            text
                        }
                        introduction {
                            text
                        }
                        name_label {
                            text
                        }
                        email_label {
                            text
                        }
                        submit_label {
                            text
                        }
                        introduction_footer {
                          text
                        }
                        footer_policy {
                          text
                        }
                        error_message
                        success_message
                    }
                }
            }
        }
    }
`;

export default SubscribeFooter;
