import React, { Fragment } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import MachineNavigation from './machine-navigation'

import { parseMachineNavigationData, parseMachineLevelsData, parseMachineRangeData, parseMachineLinePlaceholders, parseMachineTypePlaceholders, parseMachineLinesData } from '../../utils/selectors'

const Index = ({ onMenuOpened, isDark, onItemClicked, onMachinesBackTo, machinesBackTo, machinesOpen, machineBackToRangeLabel, machineBackToSeriesLabel }) => (
  <Fragment>
    <StaticQuery
      query={query}
      render={data => (
        <MachineNavigation
          firstOverview={parseMachineLevelsData(data.allPrismicMachineOverview)}
          secondLine={parseMachineLinesData(data.allPrismicMachineLine)}
          machines={parseMachineNavigationData(data.allPrismicMachine)}
          linePlaceholders={parseMachineLinePlaceholders(data.allPrismicMachineLinePlaceholder || [])}
		  typePlaceholders={parseMachineTypePlaceholders(data.allPrismicMachineTypePlaceholder || [])}
		  machineBackToRangeLabel={machineBackToRangeLabel}
		  machineBackToSeriesLabel={machineBackToSeriesLabel}
		  onMenuOpened={onMenuOpened}
		  machinesOpen={machinesOpen}
		//   onMachinesBackTo={onMachinesBackTo}
		//   machinesBackTo={machinesBackTo}
          isDark={isDark}
          onItemClicked={onItemClicked}
          machineLabel={
            data.allPrismicMenu.edges[0].node.data.machine_navigation_label.text
          }
          filterOneText={
            data.allPrismicMenu.edges[0].node.data.machine_filter_one_label.text
          }
          filterTwoText={
            data.allPrismicMenu.edges[0].node.data.machine_filter_two_label.text
          }
          filterThreeText={
            data.allPrismicMenu.edges[0].node.data.machine_filter_three_label
              .text
          }
        />
      )}
    />
  </Fragment>
)

const query = graphql`
  query GetAllMachinesForNavigation {
	allPrismicMachineOverview {
		edges {
			node {
				data {
					menu_order
					image {
						alt
						copyright
						url
					}
					title {
						html
						text
					}
				}
				uid
			}
		}
	}
	
	allPrismicMachineLine {
		edges {
			node {
				data {
					image {
						url
					}
					machine_overview {
						uid
						slug
					}
					title {
						text
					}
					menu_order
				}
				uid
			}
		}
	}

	allPrismicMachine(sort: { fields: data___menu_order, order: ASC }) {
		edges {
			node {
				uid
				data {
					menu_order
					machine_name {
						html
						text
					}
					navigation_image {
						url
					}
					treat_as_single_machine
					espresso_count
					milk_systems
					milk_systems_2
					milk_systems_3
					accessories
					features
					machine_line {
						uid
						slug
					}
					body1 {
						items {
							machine_type {
								document {
									...MachineTypeNavigation
								}
							}
						}
					}
				}
			}
		}
    }


	# allPrismicMachineLinePlaceholder {
	# 	edges {
	# 		node {
	# 			data {
	# 				image {
	# 					url
	# 				}
	# 				link {
	# 					text
	# 				}
	# 				overview {
	# 					uid
	# 					slug
	# 				}
	# 				title {
	# 					text
	# 				}
	# 				menu_order
	# 			}
	# 		}
	# 	}
	# }

	# allPrismicMachineTypePlaceholder {
	# 	edges {
	# 		node {
	# 			data {
	# 				espresso_count
	# 				image {
	# 					url
	# 				}
	# 				line {
	# 					uid
	# 					slug
	# 				}
	# 				milk_systems
	# 				milk_systems_2
	# 				milk_systems_3
	# 				title {
	# 					text
	# 				}
	# 			}
	# 		}
	# 	}
	# }

	# allPrismicMachinePlaceholder {
	# 	edges {
	# 	node {
	# 		data {
	# 		image {
	# 			url
	# 		}
	# 		link {
	# 			text
	# 			raw {
	# 			type
	# 			text
	# 			}
	# 			html
	# 		}
	# 		espresso_count
    #       	milk_systems
	# 		title {
	# 			text
	# 		}
	# 		overview {
	# 			uid
	# 		}
	# 		}
	# 	}
	# 	}
	# }

    allPrismicMenu {
      edges {
        node {
          data {
            button_label {
              text
            }
            machine_navigation_label {
              text
            }
            machine_menu_show_all_label {
              text
            }
            machine_filter_one_label {
              text
            }
            machine_filter_two_label {
              text
            }
            machine_filter_three_label {
              text
            }
            body {
              items {
                background_image {
                  url
                }
                title {
                  text
                }
                link {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Index
