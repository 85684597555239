import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import SVG from 'react-inlinesvg'
import Overlay from '../overlay/overlay'

import { MenuButton, MenuLabel } from '../header/styles'
import Filter from '../filter/filter'
import { navigate } from 'gatsby';
import { OverviewContainer, RangeContainer, FiltersContainer, FilterButton, FilterButtonsGroup, FilterGroup, FilterLabel, BackToAll } from './styles'
import { isWide } from '../../utils/responsive';
import MachineOverview from '../machine-overview/machineOverview';
import MachineCard from "../machine-card/machineCard";
import { console } from 'window-or-global'
import MachineLine from '../machine-line/machineLine'


const LEVEL = {
	OVERVIEW_1: 1,
	LINE_2: 2,
	MACHINES_3: 3,
}


class MachineNavigation extends React.Component {
	
	constructor(props) {
		super(props)
		// console.log('firstOverview:', props.firstOverview)
		// console.log('secondLine:', props.secondLine)
		// console.log('machines:', props.machines)
		// console.log('second__linePlaceholders:', props.linePlaceholders)
		// console.log('typePlaceholders:', props.typePlaceholders)

		this.onMenuToggleClicked = this.onMenuToggleClicked.bind(this)
		
		this.state = {
			open: false,

			currentOverview: '',
			currentOverviewTitle: '',
			currentLine: '',
			currentLevel: LEVEL.OVERVIEW_1,

			// machines: props.machines,
			machines: [],

			filterActive: false,
			filters: [
				{
					name: 'espressoCount',
					displayName: props.filterOneText,
					options: [
						{
							active: false,
							text: '2',
							icon: '/images/cup_v2.svg',
						},
						{
							active: false,
							text: '4',
							icon: '/images/cup_v2.svg',
						},
						{
							active: false,
							text: '6',
							icon: '/images/cup_v2.svg',
						},
						{
							active: false,
							text: '8',
							icon: '/images/cup_v2.svg',
						},
					],
				},
				{
					name: 'milk_systems',
					displayName: props.filterTwoText,
					options: [
						{
							active: false,
							text: '1-Step',
						},
						{
							active: false,
							text: '1.5-Step',
						},
						{
							active: false,
							text: '2-Step',
						},
					],
				}
			]
		}
	}

	slugfy = (value) => {
		if(!value) return value;
		const regex = /[^a-zA-Z0-9-]/g;
		return value.replace(regex, "").toLowerCase();
	}

	findTypeURL = (item) => {
		// console.log(item);
		if(!item.types || item.types.length <= 0) return null;

		for (let i = 0; i < item.types.length; i++) {
			const type = item.types[i];
			const machineName = this.slugfy(item.name);
			const typeName = this.slugfy(type.data.machine_type_name.text);
			// console.log(i, machineName, typeName);
			if(machineName && (machineName == typeName)) {
				// console.log(item)
				// console.log(type)
				const url = `${item.url}#${type.uid}`;
				// console.log("MachineNavigation -> url", url)
				return url;
			}
		}

		return null;
	}
	
	getMachinesForLine = () => {
		const line = this.state.currentLine;
        // console.log("MachineNavigation -> getMachinesForLine -> line", line)

		const list = [];
		for (let i = 0; i < this.props.machines.length; i++) {
			const item = this.props.machines[i];
            // console.log("MachineNavigation -> item", item)
			if(item.line === line) {
				// console.log("MachineNavigation -> item", item)
				const url = this.findTypeURL(item);
				if(url) {
                    // console.log("MachineNavigation -> url", url)
					item.url = url;
				}
				list.push(item);
			}
		}

		for (let i = 0; i < this.props.typePlaceholders.length; i++) {
			const item = this.props.typePlaceholders[i];
            // console.log("MachineNavigation -> getMachinesFor -> item", item)
			if(item.line === line) {
				list.push(item);
			}
		}

		return list;
		// return this.props.machines.filter(item => item.overview.toLowerCase() === overview.toLowerCase());
	}

	updateMachinesList = () => {
		const {filters, filterActive} = this.state;
        // console.log("MachineNavigation -> updateMachinesList -> filterActive", filterActive)
		
		// const machines = this.getMachinesFor(this.props.machinesBackTo);
		const machines = this.getMachinesForLine();
        // console.log("MachineNavigation -> updateMachinesList -> machines", machines)
		
		// this.setState({machines});

		if(!filterActive) {
			this.setState({machines})
			return;
		}

		const espressoCount = [];
       	const milk_systems = [];
		
		for (const option of filters[0].options) {
			if(option.active) {
				espressoCount.push(option.text);
			}
		}
		
		for (const option of filters[1].options) {
			if(option.active) {
				milk_systems.push(option.text);
			}
		}
		
		// console.log("MachineNavigation -> updateMachinesList -> espressoCount", espressoCount)
		// console.log("MachineNavigation -> updateMachinesList -> milk_systems", milk_systems)
		 
		const list = [];
		for (const machine of machines) {
			
			// both have active items
			if(espressoCount.length > 0 && milk_systems.length > 0) {
				if(espressoCount.includes(machine.espressoCount) && milk_systems.includes(machine.milk_systems)) {
					list.push(machine);
					continue;
				}
				if(espressoCount.includes(machine.espressoCount) && milk_systems.includes(machine.milk_systems_2)) {
					list.push(machine);
					continue;
				}
				if(espressoCount.includes(machine.espressoCount) && milk_systems.includes(machine.milk_systems_3)) {
					list.push(machine);
					continue;
				}

				
			}
			
			// only espressoCount
			if(espressoCount.length > 0 && milk_systems.length === 0) {
				if(espressoCount.includes(machine.espressoCount)) {
					list.push(machine);
				}

				continue;
			}
			
			// only milk_systems
			if(espressoCount.length === 0 && milk_systems.length > 0) {
				if(milk_systems.includes(machine.milk_systems)) {
					// console.log('milk_systems:', milk_systems, '  |  m:', machine.milk_systems);
					list.push(machine);
					continue;
				}
				if(machine.milk_systems_2 && milk_systems.includes(machine.milk_systems_2)) {
					// console.log('milk_systems:', milk_systems, '  |  m_2:', machine.milk_systems_2);
					list.push(machine);
					continue;
				}
				if(machine.milk_systems_3 && milk_systems.includes(machine.milk_systems_3)) {
					// console.log('milk_systems:', milk_systems, '  |  m_3:', machine.milk_systems_3);
					list.push(machine);
					continue;
				}
			}
		}

		// console.log(list);
		this.setState({machines: list});
	}





	onToggleFilter = (filterIndex, optionIndex) => {
		// console.log(filterIndex, optionIndex);
		const filters = [...this.state.filters];
		filters[filterIndex].options[optionIndex].active = !filters[filterIndex].options[optionIndex].active;
        let filterActive = false;
		for (let i = 0; i < filters.length; i++) {
			for (let j = 0; j < filters[i].options.length; j++) {
				if(filters[i].options[j].active) {
					filterActive = true;
				}
			}
		}
		
		this.setState({filters, filterActive}, () => {
			this.updateMachinesList();
		});
	}

	
	onGotoMachine = (machine) => {
        // console.log("onGotoMachine -> machine", machine)
		if(machine.isPlaceholder) {
			if(!machine.link) {
				return;
			}
			if(machine.link && machine.link.length > 5) {
				navigate(machine.link);
				// navigate('/machines/cameo#accessories');
			}
			return;
		}
		this.setState({open: false});
	}

	
	
	
	onMachineOverviewClick = (data) => {
		// console.log("MachineNavigation -> onMachineOverviewClick -> uid", uid)
		this.setState({currentLevel: LEVEL.LINE_2, currentOverview: data.uid, currentOverviewTitle: data.title});
	}
	
	
	onMachineLineClick = (data) => {
		// console.log("onMachineLineClick ->", data);

		if(data.isPlaceholder === true) {
			if(!data.link) {
				return;
			}
			if(data.link && data.link.length > 5) {
				navigate(data.link);
				// navigate('/machines/cameo#accessories');
			}
			return;
		}

		this.setState({currentLevel: LEVEL.MACHINES_3, currentLine: data.uid}, () => {
			this.updateMachinesList();
		})
	}


	getCurrentLine = () => {
		const list = [];
		const overview = this.state.currentOverview;

		for (let i = 0; i < this.props.secondLine.length; i++) {
			const line = this.props.secondLine[i];
			if(line.machine_overview_parent === overview) {
				list.push(line);
			}
		}
		
		for (let i = 0; i < this.props.linePlaceholders.length; i++) {
			const line = this.props.linePlaceholders[i];
			if(line.overview === overview) {
				list.push(line);
			}
		}

		return list;
	}


	onMenuToggleClicked = () => {
		this.props.onMenuOpened();
		this.setState({ open: !this.state.open, currentLevel: LEVEL.OVERVIEW_1, currentLine: null, currentOverviewTitle: '' });
		// this.props.onMachinesBackTo();
	}


	onBackArrowClick = () => {
		// console.log(this.state.currentLevel);
		if(this.state.currentLevel === LEVEL.LINE_2) {
			this.setState({currentLevel: LEVEL.OVERVIEW_1})
		}
		if(this.state.currentLevel === LEVEL.MACHINES_3) {
			this.setState({currentLevel: LEVEL.LINE_2})
		}
	}


	getArrowLabel = () => {
		if(this.state.currentLevel === LEVEL.LINE_2) 
			return this.props.machineBackToRangeLabel;
		
		if(this.state.currentLevel === LEVEL.MACHINES_3) 
			return this.props.machineBackToSeriesLabel;

		return null;
	}


	render() {
		const { open, filters, machines, currentLevel } = this.state;
		const { firstOverview, isDark, onItemClicked, machineLabel, machinesBackTo } = this.props;
        // console.log("render -> machinesBackTo", machinesBackTo)

		return (
			<Fragment>
				<MenuButton isDark={isDark} onClick={this.onMenuToggleClicked}>
					{isWide && <MenuLabel>{machineLabel}</MenuLabel>}
					<SVG src={open ? '/images/X.svg' : '/images/machine-toggle.svg'} />
				</MenuButton>

				{this.props.machinesOpen && this.getArrowLabel() &&
				<BackToAll onClick={()=>{this.onBackArrowClick()}}>
					<SVG src={'/images/machine-all-arrow.svg'} />
					<p>{this.getArrowLabel()}</p>
				</BackToAll>}

				<Overlay open={open} backgroundColour="#FFF">

					
					{currentLevel === LEVEL.MACHINES_3 &&
					<FiltersContainer>
						{filters && filters.map((filter, index) => 
							<FilterGroup key={filter.name}>
								<FilterLabel>{filter.displayName}</FilterLabel>
								<FilterButtonsGroup>
									{filter.options.map((option, optionIndex) =>
										<FilterButton active={option.active} key={option.text} onClick={() => this.onToggleFilter(index, optionIndex)}>
											{ option.icon && <SVG src={option.icon} /> }
											{ option.text }
										</FilterButton>	
									)}
								</FilterButtonsGroup>
							</FilterGroup>
						)}
					</FiltersContainer>}
					
				
					{currentLevel === LEVEL.OVERVIEW_1 &&
						<OverviewContainer>
							{this.props.firstOverview && this.props.firstOverview.map((machine, index) =>
								<MachineOverview
									key={`${machine.uid}_${index}`}
									data={machine}
									name={machine.title}
									uid={machine.uid}
									navImage={machine.image}
									handleClick={this.onMachineOverviewClick}
									/>
							)}
						</OverviewContainer>
					}

					{currentLevel === LEVEL.LINE_2 && 
						<OverviewContainer>
							{this.getCurrentLine().map((machine, index) => 
								<MachineLine
									key={`${machine.uid}_${index}`}
									data={machine}
									name={machine.title}
									title={this.state.currentOverviewTitle}
									isPlaceholder={machine.isPlaceholder || false}
									uid={machine.uid}
									navImage={machine.image}
									handleClick={this.onMachineLineClick}
									/>
							)}
						</OverviewContainer>
					}

					
					{currentLevel === LEVEL.MACHINES_3 && machines &&
					<RangeContainer>
						{machines.map((item, index) => 
							<MachineCard
								key={`${item.name}_${index}`}
								name={item.name}
								data={item}
								isPlaceholder={item.isPlaceholder || false}
								title={this.state.currentOverviewTitle}
								url={item.url}
								navImage={item.navImage}
								handleClick={this.onGotoMachine}
							/>
						)}
					</RangeContainer>
					}

				</Overlay>
				
			</Fragment>
		)
	}
}

export default MachineNavigation
